import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ContractModel } from '../../../models';
import { ContractDto, ContractUpdateDto } from '../../dtos';

@Injectable({ providedIn: 'root' })
export class ContractService {
    constructor(
        private http: HttpClient,
    ) {
    }

    public getMunicipalityContracts(municipalityId: string): Observable<ContractModel[]> {
        const url = `${environment.portalApiUrl}/concession-contracts?municipalityId=${municipalityId}`;

        return this.http.get<ContractDto[]>(url).pipe(
            map((dtos: ContractDto[]) => dtos.map((dto: ContractDto) => this.mapContractFromDto(dto)))
        );
    }

    public updateContract(contractId: string, contract: Partial<ContractModel>): Observable<ContractModel> {
        return this.http.put<ContractDto>(
            `${environment.portalApiUrl}/concession-contracts/${contractId}`, this.mapContractUpdateDataToDto(contract)
        ).pipe(
            map((dto: ContractDto) => this.mapContractFromDto(dto))
        );
    }

    private mapContractFromDto(dto: ContractDto): ContractModel {
        return {
            id: dto.id,
            municipalityId: dto.municipalityId,
            gridOperatorId: dto.gridOperatorId,
            contractKey: dto.concessionContractKey,
            commodity: dto.commodity,
            contractStart: dto.concessionContractStart,
            contractEnd: dto.concessionContractEnd,
            reportingDeadline: dto.reportingDeadline,
            penaltyMax: dto.penaltyMax,
            contractDocumentUri: dto.concessionContractDocumentUri
        };
    }

    private mapContractUpdateDataToDto(contract: Partial<ContractModel>): ContractUpdateDto {
        return {
            reportingDeadline: contract.reportingDeadline,
            penaltyMax: contract.penaltyMax,
            concessionContractDocumentUri: contract.contractDocumentUri
        }
    }
}
