import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CpToasterService } from '@concession-portal/ng-dso-ui-components-fe';
import { Subject, takeUntil } from 'rxjs';
import { PHASE } from '../../../../core/enums';
import { ActivityFacade, ProcedureFacade } from '../../../../core/facades';
import { ActivityModel, PhaseModel, PhaseReportingActivityModel, ProcedureModel } from '../../../../core/models';


@Component({
    selector: 'portal-start-phase-dialog',
    templateUrl: './start-phase-dialog.component.html',
    styleUrls: ['./start-phase-dialog.component.scss', '../../styles/phase-transition.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StartPhaseDialogComponent implements OnInit, OnDestroy {
    public PHASE = PHASE;
    public isInValidState = false;
    public isInProgress = false;
    public stepCount: number = 1;
    public currentStep: number = 1;

    public phaseLabel: {[phase: string]: string } = {
        [PHASE.INDICATIVE_PROCEDURE_START]: 'Verfahren anlegen',
        [PHASE.FINAL_PROCEDURE_START]: 'Verfahren anlegen',
        [PHASE.REPORTING_START]: 'Reporting starten'
    }

    private ngDestroy: Subject<void> = new Subject();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PhaseModel,
        private dialogRef: MatDialogRef<StartPhaseDialogComponent>,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private activityFacade: ActivityFacade,
        private procedureFacade: ProcedureFacade,
        private toasterService: CpToasterService
    ) {
    }

    public ngOnInit(): void {
        this.setStepCount();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public onDataUpdated(data: PhaseModel): void {
        this.data = { ...data };
        this.setStepCount();
    }

    public onDataValid(value: boolean): void {
        this.isInValidState = value;
        this.changeDetectorRef.detectChanges();
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public onPreviousStep(): void {
        this.currentStep -= 1;
    }

    public onNextStep(): void {
        if (this.currentStep === this.stepCount) {
            this.startNextPhase();
        } else {
            this.currentStep += 1;
        }
    }

    private setStepCount(): void {
        switch (this.data.nextPhase) {
            case PHASE.INDICATIVE_PROCEDURE_START:
            case PHASE.FINAL_PROCEDURE_START:
                this.stepCount = [PHASE.QUANTITY_STRUCTURE_CREATION_END, PHASE.PUBLICATION_END].includes(this.data.currentPhase)
                    ? 2
                    : 1;
                break;

            case PHASE.REPORTING_START:
                this.stepCount = 4;
                break;

            default:
                this.stepCount = 1;
        }
    }

    private startNextPhase(): void {
        switch (this.data.nextPhase) {
            case PHASE.INDICATIVE_PROCEDURE_START:
            case PHASE.FINAL_PROCEDURE_START:
                if ([PHASE.INDICATIVE_PROCEDURE_END, PHASE.FINAL_PROCEDURE_END].includes(this.data.currentPhase)) {
                    this.switchProcedurePhase();
                } else {
                    this.startProcedurePhase();
                }
                break;

            case PHASE.REPORTING_START:
                this.startReportingPhase();
                break;

            default:
                this.startNextActivityPhase();
                break;
        }
    }

    private startNextActivityPhase(): void {
        const activity: ActivityModel = {
            id: this.data.activityId,
            endDate: this.data.endDate,
            ownerKid: this.data.ownerKid,
            phase: this.data.nextPhase,
            gridOperatorId: this.data.gridOperatorId
        };

        this.isInProgress = true;

        this.activityFacade.startPhase(activity).pipe(
            takeUntil(this.ngDestroy)
        ).subscribe({
            next: () =>  {
                this.toasterService.success('Die Phase wurde erfolgreich gestartet.');
                this.isInProgress = false;
                this.dialogRef.close();
            },
            error: () => {
                this.toasterService.error('Die Phase konnte nicht gestartet werden.');
                this.isInProgress = false;
            }
        });
    }

    private startProcedurePhase(): void {
        const procedure = this.getProcedure();

        this.isInProgress = true;

        this.procedureFacade.createProcedure(procedure).subscribe({
            next: (result: ProcedureModel) =>  {
                this.toasterService.success(`Das Verfahren "${procedure.title}" wurde erfolgreich erstellt.`);
                this.isInProgress = false;
                this.router.navigate(['procedure-detail', result.id]);
                this.dialogRef.close();
            },
            error: () => {
                this.toasterService.error('Das Verfahren konnte nicht erstellt werden.');
                this.isInProgress = false;
            },
        });
    }

    private switchProcedurePhase(): void {
        const procedure = this.getProcedure();

        this.isInProgress = true;

        this.procedureFacade.startPhase(procedure).pipe(
            takeUntil(this.ngDestroy)
        ).subscribe({
            next: () =>  {
                this.toasterService.success('Die Phase wurde erfolgreich gestartet.');
                this.isInProgress = false;
                this.dialogRef.close();
            },
            error: () => {
                this.toasterService.error('Die Phase konnte nicht gestartet werden.');
                this.isInProgress = false;
            }
        });
    }

    private getProcedure(): ProcedureModel {
        return {
            id: this.data.procedure.id,
            commodity: this.data.commodity,
            title: this.data.procedure.title,
            suspectedCompetitor: this.data.procedure.suspectedCompetitor,
            consultantId: this.data.procedure.consultantId,
            gridOperatorId: this.data.gridOperatorId,
            basedOn: this.data.procedure.basedOnProcedureId,
            endDate: this.data.endDate,
            ownerKid: this.data.ownerKid,
            phase: this.data.nextPhase,
            activities: this.data.procedure.activityIds.map((id: string) => ({ id })),
            status: this.data.status
        };
    }

    private startReportingPhase(): void {
        let municipalityNames: string[] = [];

        const activities = this.data.reporting.activities.filter(
            (activity: PhaseReportingActivityModel) => activity.selected
        );

        activities.forEach((activity: PhaseReportingActivityModel) => {
            municipalityNames.push(activity.municipalityName);
            delete(activity.selected);
        });

        this.isInProgress = true;

        this.procedureFacade.startReportingPhase(this.data.procedure.id, activities).subscribe({
            next: () => {
                this.toasterService.success(`Die Verträge der Kommunen ${municipalityNames.join(', ')} wurden erfolgreich in die Reporting-Phase übergeben.`);

                this.isInProgress = false;
                this.router.navigate(['/']);
                this.dialogRef.close();
            },
            error: () => {
                this.toasterService.error(`Die Verträge der Kommunen ${municipalityNames.join(', ')} konnten nicht in die Reporting-Phase übergeben werden.`);
                this.isInProgress = false;
            },
        });
    }
}
