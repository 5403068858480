import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { STATUS } from 'src/app/core/enums';
import { environment } from '../../../../../environments/environment';
import { ActivityModel, ContractModel } from '../../../../core/models';
import { formatDateToYYYYMMDD } from '../../../utils/date.util';
import { ActivityCreateDto, ActivityDto, ActivityUpdateDto, ActivityUpdatePhaseDto, ContractDto } from '../../dtos';


@Injectable({ providedIn: 'root' })
export class ActivityService {

    constructor(private http: HttpClient) {}

    public getActivities(): Observable<ActivityModel[]> {
        return this.http.get<ActivityDto[]>(
            `${environment.portalApiUrl}/activities`
        ).pipe(map((dtos: ActivityDto[]) => dtos.map((dto: ActivityDto) => this.mapActivityFromDto(dto))));
    }

    public getActivity(activityId: string): Observable<ActivityModel> {
        return this.http.get<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}`
        ).pipe(map((dto: ActivityDto) => this.mapActivityFromDto(dto)));
    }

    public createActivity(activity: Partial<ActivityModel>): Observable<ActivityModel> {
        return this.http.post<ActivityDto>(
            `${environment.portalApiUrl}/activities`, this.mapToCreateDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapActivityFromDto(dto)));
    }

    public updateActivity(activityId: string, activity: Partial<ActivityModel>): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}`, this.mapToUpdateDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapActivityFromDto(dto)));
    }

    public updateActivityStatus(activityId: string, status: STATUS): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}/status`, {status: status}
        ).pipe(
             map((dto: ActivityDto) => this.mapActivityFromDto(dto))
        );
    }

    public setPhase(activity: ActivityModel): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activity.id}/phase`, this.mapToUpdatePhaseDto(activity)
        ).pipe(map((dto: ActivityDto) => this.mapActivityFromDto(dto)));
    }

    public endPhase(activityId: string): Observable<ActivityModel> {
        return this.http.put<ActivityDto>(
            `${environment.portalApiUrl}/activities/${activityId}/end-current-phase`, {}
        ).pipe(map((dto: ActivityDto) => this.mapActivityFromDto(dto)));
    }

    private mapActivityFromDto(dto: ActivityDto): ActivityModel {
        return {
            id: dto.id,
            ownerKid: dto.owner.kid,
            ownerName: dto.owner.givenName + ' ' + dto.owner.lastName,
            commodity: dto.commodity,
            phase: dto.phase,
            phaseStartDate: dto.phaseStartDate,
            municipalityId: dto.municipality.id,
            gridOperatorId: dto.gridOperatorId,
            penaltyMax: dto.concessionContract?.penaltyMax,
            concessionContractId: dto.concessionContract?.id,
            concessionContractKey: dto.concessionContract?.concessionContractKey,
            contract: this.mapContractFromDto(dto.concessionContract),
            status: dto.status,
            endDate: dto.endDate,
            notes: dto.notes
        };
    }

    private mapContractFromDto(dto: ContractDto): ContractModel {
        if (!dto) {
            return null;
        }

        return {
            id: dto.id,
            municipalityId: dto.municipalityId,
            gridOperatorId: dto.gridOperatorId,
            contractKey: dto.concessionContractKey,
            commodity: dto.commodity,
            contractStart: dto.concessionContractStart,
            contractEnd: dto.concessionContractEnd,
            reportingDeadline: dto.reportingDeadline,
            penaltyMax: dto.penaltyMax,
            contractDocumentUri: dto.concessionContractDocumentUri
        };
    }

    private mapToCreateDto(model: Partial<ActivityModel>): ActivityCreateDto {
        return {
            municipalityId: model.municipalityId,
            concessionContractId: model.concessionContractId,
            commodity: model.commodity,
            gridOperatorId: model.gridOperatorId,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase
        };
    }

    private mapToUpdateDto(model: Partial<ActivityModel>): ActivityUpdateDto {
        return {
            gridOperatorId: model.gridOperatorId,
            notes: model.notes,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
        };
    }

    private mapToUpdatePhaseDto(model: ActivityModel): ActivityUpdatePhaseDto {
        return {
            gridOperatorId: model.gridOperatorId,
            endDate: formatDateToYYYYMMDD(model.endDate),
            ownerKid: model.ownerKid,
            phase: model.phase
        };
    }
}
