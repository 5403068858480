import { CP_COMMODITY } from "@concession-portal/ng-dso-ui-components-fe";

export interface ContractModel {
    id: string;
    municipalityId?: string;
    gridOperatorId?: string;
    contractKey: string;
    commodity: CP_COMMODITY;
    contractStart: string;
    contractEnd: string;
    reportingDeadline: string;
    penaltyMax?: number;
    contractDocumentUri?: string;
}
