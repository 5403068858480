import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { format, isBefore, isValid } from 'date-fns';
import { Subject, takeUntil } from 'rxjs';
import { PhaseModel, PhaseReportingActivityModel } from '../../../../core/models';

export interface FormTableRow {
    activityId: string;
    contractDocumentUri: string;
    contractEnd: string;
    contractStart: string;
    municipalityName: string;
    penaltyInfinite: boolean;
    penaltyMax: number;
    reportingDeadline: string;
    errors?: RowError[];
    valid?: boolean;
}

type RowError = 'endDateBeforeStart' | 'reportDateBeforeStart';

@Component({
    selector: 'portal-reporting-contracts-data',
    templateUrl: './reporting-contracts-data.component.html',
    styleUrls: ['./reporting-contracts-data.component.scss', '../../styles/phase-transition.scss'],
    standalone: false
})
export class ReportingContractsDataComponent implements OnInit, OnDestroy {
    @Input()
    public data: PhaseModel;

    @Output()
    public dataUpdated = new EventEmitter<PhaseModel>();

    @Output()
    public dataValid = new EventEmitter<boolean>();

    public activeColumns: string[] = ['municipalityName', 'contractDocumentUri', 'penaltyMax', 'contractStart', 'contractEnd', 'reportingDeadline'];
    public dataSource: MatTableDataSource<FormTableRow>;
    public formValid: boolean = false;

    private formValuesChanged = new Subject<FormTableRow>;
    private readonly ngDestroy = new Subject<void>();

    public ngOnInit(): void {
        const formTableRows: FormTableRow[] = this.mapPhaseDataToFormTableRows(this.data.reporting.activities.filter((item: PhaseReportingActivityModel) => item.selected));
        this.dataSource = new MatTableDataSource<FormTableRow>(formTableRows);

        this.dataSource.data.forEach((row: FormTableRow) => this.validateRow(row));

        this.validateForm();
        this.bindFormChanges();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.unsubscribe();
    }

    public onFormValuesChanged(row: FormTableRow): void {
        this.formValuesChanged.next(row);
    }

    public trackBy(index: number, item: FormTableRow): string {
        return item.activityId;
    }

    public onPenaltyInfiniteClick(row: FormTableRow) {
        if (row.penaltyInfinite) {
            row.penaltyMax = null;
        }

        this.formValuesChanged.next(row);
    }

    private bindFormChanges(): void {
        this.formValuesChanged.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe((row: FormTableRow) => {
            this.validateRow(row);
            this.validateForm();

            const activities: PhaseReportingActivityModel[] = [];

            this.data.reporting.activities.forEach((activity: PhaseReportingActivityModel) => {

                if (activity.activityId === row.activityId) {
                    activities.push({
                        ...activity,
                        contractDocumentUri: row.contractDocumentUri,
                        contractEnd: format(new Date(row.contractEnd), 'yyyy-MM-dd'),
                        contractStart: format(new Date(row.contractStart), 'yyyy-MM-dd'),
                        penaltyMax: row.penaltyMax,
                        penaltyInfinite: row.penaltyInfinite,
                        reportingDeadline: format(new Date(row.reportingDeadline), 'yyyy-MM-dd'),
                    });
                } else {
                    activities.push(activity);
                }
            });

            const data: PhaseModel = {
                ...this.data,
                reporting: {
                    ...this.data.reporting,
                    activities
                }
            };

            this.dataUpdated.emit(data);
            this.dataValid.emit(this.formValid);
        });
    }

    private mapPhaseDataToFormTableRows(activities: PhaseReportingActivityModel[]): FormTableRow[] {
        const formTableRows: FormTableRow[] = [];

        activities.forEach((item: PhaseReportingActivityModel) =>
            formTableRows.push({
                activityId: item.activityId,
                contractDocumentUri: item.contractDocumentUri,
                contractEnd: item.contractEnd,
                contractStart: item.contractStart,
                errors: [],
                valid: false,
                municipalityName: item.municipalityName,
                penaltyInfinite: item.penaltyInfinite,
                penaltyMax: item.penaltyMax,
                reportingDeadline: item.reportingDeadline,
            }));

        return formTableRows
    }

    private validateForm(): void {
        this.formValid = this.dataSource.data.find((row: FormTableRow) => !row.valid) === undefined;
    }

    private validateRow(row: FormTableRow): void {
        if (isBefore(new Date(row.contractEnd), new Date(row.contractStart))) {
            row.errors.push('endDateBeforeStart')
        } else {
            row.errors = row.errors.filter((error: RowError): boolean => error !== 'endDateBeforeStart');
        }

        row.valid = (row.penaltyInfinite || (!row.penaltyInfinite && row.penaltyMax >= 0))
            && isValid(new Date(row.contractStart))
            && isValid(new Date(row.contractEnd))
            && isValid(new Date(row.reportingDeadline))
            && row.errors.length === 0;
   }
}
