export interface PhaseReportingModel {
    base: PhaseReportingBaseModel;
    activities: PhaseReportingActivityModel[];
}

export interface PhaseReportingBaseModel {
    contractEnd: string;
    contractStart: string;
    penaltyMax: number;
    penaltyInfinite: boolean;
    reportingDeadline: string;
}

export interface PhaseReportingActivityModel {
    activityId: string;
    contractKey: string;
    municipalityName: string;
    ownerKid: string;
    gridOperatorId: string;
    contractDocumentUri?: string;
    contractStart?: string;
    contractEnd?: string;
    penaltyMax?: number;
    penaltyInfinite?: boolean;
    reportingDeadline?: string;
    selected?: boolean;
}

export const EMPTY_PHASE_REPORTING_BASE_MODEL: PhaseReportingBaseModel = {
    contractEnd: null,
    contractStart: null,
    penaltyMax: 0,
    penaltyInfinite: false,
    reportingDeadline: null
};
