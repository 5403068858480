import { Injectable } from "@angular/core";
import { CP_COMMODITY } from "@concession-portal/ng-dso-ui-components-fe";
import { Observable, ReplaySubject, map, tap } from "rxjs";
import { ContractService } from "../data-access/services";
import { ContractModel } from "../models";

@Injectable({ providedIn: 'root' })
export class ContractFacade {

    public contract$: Observable<ContractModel>;
    public contracts$: Observable<ContractModel[]>;

    private contractSource = new ReplaySubject<ContractModel>(1);
    private contractsSource = new ReplaySubject<ContractModel[]>(1);

    constructor(private contractService: ContractService) {
        this.contract$ = this.contractSource.asObservable();
        this.contracts$ = this.contractsSource.asObservable();
    }

    public loadMunicipalityContracts(municipalityId: string, commodity: CP_COMMODITY): void {
        this.contractService.getMunicipalityContracts(municipalityId).pipe(
            map((contracts: ContractModel[]) => contracts.filter(
                (contract: ContractModel) => contract.commodity === commodity)
            )
        ).subscribe(
            (contracts: ContractModel[]) => this.contractsSource.next(contracts)
        );
    }

    public updateContract(contractId: string, contract: Partial<ContractModel>): Observable<ContractModel> {
        return this.contractService.updateContract(contractId, contract).pipe(
            tap((contract: ContractModel) => this.contractSource.next(contract))
        );
    }
}
