import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ProcedureService } from "../data-access/services";
import { PHASE, STATUS } from "../enums";
import { PhaseReportingActivityModel, ProcedureModel } from "../models";
import { ProcedureState } from "../states";


@Injectable({ providedIn: 'root' })
export class ProcedureFacade {

    public procedures$: Observable<ProcedureModel[]>;
    public procedure$: Observable<ProcedureModel>;

    constructor(
        private procedureService: ProcedureService,
        private procedureState: ProcedureState,
    ) {
        this.procedures$ = this.procedureState.procedures$;
        this.procedure$ = this.procedureState.procedure$;
    }

    public loadProcedures(): void {
        this.procedureService.getProcedures().subscribe(
            (procedures: ProcedureModel[]) => this.procedureState.setProcedures(procedures)
        );
    }

    public loadProcedure(procedureId: string): void {
        this.procedureService.getProcedure(procedureId).subscribe(
            (procedure: ProcedureModel) => {
                this.procedureState.setProcedure(procedure);
            }
        );
    }

    public startPhase(procedure: ProcedureModel): Observable<ProcedureModel> {
        return this.procedureService.setPhase(procedure).pipe(
            tap((result: ProcedureModel) => this.procedureState.setProcedure(result))
        );
    }

    public endPhase(procedureId: string): Observable<ProcedureModel> {
        return this.procedureService.endPhase(procedureId).pipe(
            tap((result: ProcedureModel) => this.procedureState.setProcedure(result))
        );
    }

    public createProcedure(procedure: ProcedureModel): Observable<ProcedureModel> {
        return this.procedureService.createProcedure(procedure).pipe(
            tap((procedure: ProcedureModel) => this.procedureState.addProcedure(procedure))
        );
    }

    public updateProcedure(procedureId: string, procedure: Partial<ProcedureModel>): Observable<ProcedureModel> {
        return this.procedureService.updateProcedure(procedureId, procedure).pipe(
            tap((result: ProcedureModel) => this.procedureState.setProcedure(result))
        );
    }

    public updateProcedureStatus(procedureId: string, status: STATUS): Observable<ProcedureModel> {
        return this.procedureService.updateProcedureStatus(procedureId, status).pipe(
            tap((result: ProcedureModel) => this.procedureState.setProcedure(result))
        );
    }

    public startReportingPhase(procedureId: string, activities: PhaseReportingActivityModel[]): Observable<ProcedureModel> {
        return this.procedureService.startReportingPhase(procedureId, activities).pipe(
            tap((procedure: ProcedureModel) => {
                if (procedure.phase === PHASE.ARCHIVED) {
                    this.procedureState.removeProcedure(procedure.id);
                } else {
                    this.procedureState.setProcedure(procedure);
                }
            })
        );
    }
}
