import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'portal-wizard-step-indicator',
    imports: [CommonModule],
    templateUrl: './wizard-step-indicator.component.html',
    styleUrls: ['./wizard-step-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CpWizardStepIndicatorComponent implements OnChanges {
    @Input()
    public stepCount: number = 0;

    @Input()
    public currentStep: number = 0;

    protected readonly Array: ArrayConstructor = Array;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    public ngOnChanges(): void {
        this.changeDetector.markForCheck();
    }

}
