import { PERMISSION, PHASE } from "../enums";

export const getPhaseName = (phase: PHASE): string => {
    switch (phase) {
        case PHASE.QUANTITY_STRUCTURE_CREATION_START :
        case PHASE.QUANTITY_STRUCTURE_CREATION_END :
            return 'Mengengerüst erstellen';
        case PHASE.PUBLICATION_START :
        case PHASE.PUBLICATION_END :
            return 'Bekanntmachung/Interessensbekundung';
        case PHASE.INDICATIVE_PROCEDURE_START :
        case PHASE.INDICATIVE_PROCEDURE_END :
            return 'Indikatives Angebot';
        case PHASE.FINAL_PROCEDURE_START :
        case PHASE.FINAL_PROCEDURE_END :
            return 'Verbindliches Angebot';
        case PHASE.REPORTING_START :
            case PHASE.ARCHIVED :
                return 'Laufende Verträge/Zusagenreporting';
        default:
            return 'Ungültige Phase'
    }
};

export const getActiveActivityPhases = (): PHASE[] => {
    return [
        PHASE.QUANTITY_STRUCTURE_CREATION_START,
        PHASE.PUBLICATION_START,
        PHASE.REPORTING_START
    ];
};

export const getStartPhases = (): PHASE[] => {
    return [
        PHASE.QUANTITY_STRUCTURE_CREATION_START,
        PHASE.PUBLICATION_START,
        PHASE.INDICATIVE_PROCEDURE_START,
        PHASE.FINAL_PROCEDURE_START,
        PHASE.REPORTING_START
    ];
};

export const getEndPhase = (phase: PHASE): PHASE => {
    switch (phase) {
        case PHASE.QUANTITY_STRUCTURE_CREATION_START:
            return PHASE.QUANTITY_STRUCTURE_CREATION_END;

        case PHASE.PUBLICATION_START:
            return PHASE.PUBLICATION_END;

        case PHASE.INDICATIVE_PROCEDURE_START:
            return PHASE.INDICATIVE_PROCEDURE_END;

        case PHASE.FINAL_PROCEDURE_START:
            return PHASE.FINAL_PROCEDURE_END;

        case PHASE.REPORTING_START:
            return PHASE.ARCHIVED;

        default:
            return null;
    }
};

export const getNextPhase = (phase: PHASE): PHASE => {
    switch (phase) {
        case PHASE.QUANTITY_STRUCTURE_CREATION_END:
            return PHASE.PUBLICATION_START;

        case PHASE.PUBLICATION_END:
            return PHASE.INDICATIVE_PROCEDURE_START;

        case PHASE.INDICATIVE_PROCEDURE_END:
            return PHASE.FINAL_PROCEDURE_START;

        case PHASE.FINAL_PROCEDURE_END:
            return PHASE.REPORTING_START;

        default:
            return phase;
    }
};

export const isPhaseCompleted = (phase: PHASE, currentPhase: PHASE): boolean => {
    switch (currentPhase) {
        case PHASE.QUANTITY_STRUCTURE_CREATION_END:
            return [PHASE.QUANTITY_STRUCTURE_CREATION_START].includes(phase);

        case PHASE.PUBLICATION_START:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
            ].includes(phase);

        case PHASE.PUBLICATION_END:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
            ].includes(phase);

        case PHASE.INDICATIVE_PROCEDURE_START:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
            ].includes(phase);

        case PHASE.INDICATIVE_PROCEDURE_END:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
                PHASE.INDICATIVE_PROCEDURE_START
            ].includes(phase);

        case PHASE.FINAL_PROCEDURE_START:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
                PHASE.INDICATIVE_PROCEDURE_START,
            ].includes(phase);

        case PHASE.FINAL_PROCEDURE_END:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
                PHASE.INDICATIVE_PROCEDURE_START,
                PHASE.FINAL_PROCEDURE_START
            ].includes(phase);

        case PHASE.REPORTING_START:
            return [
                PHASE.QUANTITY_STRUCTURE_CREATION_START,
                PHASE.PUBLICATION_START,
                PHASE.INDICATIVE_PROCEDURE_START,
                PHASE.FINAL_PROCEDURE_START
            ].includes(phase);

        default:
            return false;
    }
};

export const isPhaseActive = (phase: PHASE): boolean => {
    return getStartPhases().includes(phase);
};

export const isMetadataEditingAllowed = (phase: PHASE, permissions: PERMISSION[], access: { mia: boolean; robin: boolean; karla: boolean, kosmos: boolean }): boolean => {
    if (!isPhaseActive(phase) || !permissions.includes(PERMISSION.WRITE_ACTIVITY) || !permissions.includes(PERMISSION.WRITE_PROCEDURE)) {
        return false;
    }

    switch (phase) {
        case PHASE.QUANTITY_STRUCTURE_CREATION_START:
        case PHASE.PUBLICATION_START:
            return access.mia || access.kosmos;

        case PHASE.INDICATIVE_PROCEDURE_START:
        case PHASE.FINAL_PROCEDURE_START:
            return access.robin || access.kosmos;

        case PHASE.REPORTING_START:
            return access.karla || access.kosmos;

        default:
            return false;
    }
};
