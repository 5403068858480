import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { PhaseModel, PhaseReportingActivityModel } from '../../../../core/models';
import { CONCESSION_KEY_VALIDATOR_REGEX } from '../../directives';


export interface FormTableRow {
    activityId: string;
    contractKey: string;
    contractKeyDisabled: boolean;
    municipalityName: string;
    selected: boolean;
    errors: string[]
}

@Component({
    selector: 'portal-reporting-select-municipalities',
    templateUrl: './reporting-select-municipalities.component.html',
    styleUrls: ['./reporting-select-municipalities.component.scss', '../../styles/phase-transition.scss'],
    standalone: false
})
export class ReportingSelectMunicipalitiesComponent implements OnInit, OnDestroy {
    @Input()
    public data: PhaseModel;

    @Output()
    public dataUpdated = new EventEmitter<PhaseModel>();

    @Output()
    public dataValid = new EventEmitter<boolean>();

    public activeColumns: string[] = ['select', 'municipalityName', 'contractKey'];
    public dataSource: MatTableDataSource<FormTableRow>;
    public allRowsToggleChecked: boolean = false;
    public allRowsToggleIntermediate: boolean = false;

    private formValuesChanged = new Subject<FormTableRow>;
    private readonly ngDestroy = new Subject<void>();

    public ngOnInit(): void {
        const formTableRows: FormTableRow[] = this.mapPhaseDataToFormTableRows();
        this.dataSource = new MatTableDataSource<FormTableRow>(formTableRows);

        this.initAllRowsToggle();
        this.validateForm();

        this.bindFormChanges();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.unsubscribe();
    }

    public trackBy(index: number, item: FormTableRow): string {
        return item.activityId;
    }

    public toggleAllRows(): void {
        this.dataSource.data.forEach((row: FormTableRow) => row.selected = this.allRowsToggleChecked);
        this.allRowsToggleIntermediate = false;
        this.validateForm();
        this.emitDataChange();
    }

    public onRowSelectionChanged() {
        this.emitDataChange();
        this.validateForm();

        const selectedCount: number = this.dataSource.data.filter(
            (row: FormTableRow) => row.selected).length;

        if (selectedCount === 0) {
            this.allRowsToggleIntermediate = false;
            this.allRowsToggleChecked = false;
            return;
        }

        if (selectedCount === this.dataSource.data.length) {
            this.allRowsToggleIntermediate = false;
            this.allRowsToggleChecked = true;
            return
        }

        this.allRowsToggleIntermediate = true;
        this.allRowsToggleChecked = false;
    }

    public onFormValuesChanged(row: FormTableRow): void {
        this.formValuesChanged.next(row);
    }

    private bindFormChanges(): void {
        this.formValuesChanged.pipe(
            takeUntil(this.ngDestroy)
        ).subscribe((row: FormTableRow) => {
            this.validateRow(row);
            this.validateForm();

            this.emitDataChange();
        });
    }

    private initAllRowsToggle(): void {
        const selectedCount: number = this.dataSource.data.filter((row: FormTableRow) => row.selected).length;
        this.allRowsToggleChecked = selectedCount === this.data.reporting.activities.length;
        this.allRowsToggleIntermediate = selectedCount > 0 && !this.allRowsToggleChecked;
    }

    private emitDataChange(): void {
        let activities: PhaseReportingActivityModel[] = [];

        this.data.reporting.activities.forEach((activity: PhaseReportingActivityModel) => {
            const row: FormTableRow = this.dataSource.data.find((row: FormTableRow) => activity.activityId === row.activityId);
            activities.push({
                ...activity,
                contractKey: row.contractKey,
                selected: row.selected,
            });
        });

        const data: PhaseModel = {
            ...this.data,
            reporting: {
                ...this.data.reporting,
                activities
            }
        };

        this.dataUpdated.emit(data);
    }

    private mapPhaseDataToFormTableRows(): FormTableRow[] {
        const formTableRows: FormTableRow[] = [];

        this.data.reporting.activities.forEach((item: PhaseReportingActivityModel) =>
            formTableRows.push({
                activityId: item.activityId,
                contractKey: item.contractKey ?? '',
                contractKeyDisabled: item.contractKey ? true : false,
                municipalityName: item.municipalityName,
                selected: item.selected,
                errors: []
            }));

        return formTableRows
    }

    private validateRow(row: FormTableRow): void {
        if (!CONCESSION_KEY_VALIDATOR_REGEX.test(row.contractKey)) {
            if (!row.errors.includes(row.contractKey)) {
                row.errors.push('contractKey');
            }
        } else {
            row.errors = [];
        }
    }

    private validateForm(): void {
        const allSelectedRowsHaveValidContractKey: boolean = this.dataSource.data.find(
            (row: FormTableRow) => row.selected && !this.hasValidContractKey(row)
        ) === undefined;

        const atLeastOneRowSelected: boolean = this.dataSource.data.find(
            (row: FormTableRow) => row.selected
        ) !== undefined;

        const formValid = allSelectedRowsHaveValidContractKey && atLeastOneRowSelected;
        this.dataValid.emit(formValid);
    }

    private hasValidContractKey(row: FormTableRow): boolean {
        return row.contractKey.length > 0 && row.errors.length === 0;
    }
}
