import { CP_COMMODITY } from "@concession-portal/ng-dso-ui-components-fe";
import { PHASE, STATUS } from "../enums";
import { ContractModel } from "./contract.model";

export interface ActivityModel {
    id?: string;
    ownerKid: string;
    ownerName?: string;
    phase: PHASE;
    gridOperatorId: string;
    endDate: string;
    commodity?: CP_COMMODITY;
    phaseStartDate?: string;
    penaltyMax?: number;
    status?: STATUS;
    municipalityId?: string;
    concessionContractId?: string;
    concessionContractKey?: string;
    contract?: ContractModel;
    notes?: string;
}
