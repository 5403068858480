export enum PERMISSION {
    READ_DATA = 'read_data',
    WRITE_CONCESSION_CONTRACTS = 'write_concession_contracts',
    WRITE_CONSULTANTS = 'write_consultants',
    WRITE_DEPARTMENTS = 'write_departments',
    WRITE_KEY_ACCOUNT_MANAGERS = 'write_key_account_managers',
    WRITE_MUNICIPALITIES = 'write_municipalities',
    WRITE_NET_CENTERS = 'write_net_centers',
    WRITE_NET_CENTER_MANAGERS = 'write_net_center_managers',
    READ_USERS = 'read_users',
    WRITE_USERS = 'write_users',
    WRITE_PROCEDURE = 'write_procedure',
    WRITE_ACTIVITY = 'write_activity'
}
