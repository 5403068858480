import { CP_COMMODITY } from "@concession-portal/ng-dso-ui-components-fe";
import { PHASE, STATUS } from "../enums";

export interface ProcedureModel {
    id?: string;
    activities: ProcedureActivityModel[];
    commodity: CP_COMMODITY;
    consultantId: string;
    endDate: string;
    gridOperatorId: string;
    ownerKid: string;
    ownerName?: string;
    phase: PHASE;
    status: STATUS;
    suspectedCompetitor: string;
    title: string;
    basedOn?: string;
    phaseStartDate?: string;
    notes?: string;
}

export interface ProcedureActivityModel {
    id: string;
    concessionContractKey?: string;
    contractDocumentUri?: string;
    municipalityId?: string;
    municipalityName?: string;
    penaltyMax?: number | null; // Todo: @thorsten: jetzt ist hier numner, null und undefined erlaubt - können wir das eingrenzen?
    reportingDeadline?: string;
}
