export enum MUNICIPALITY_TYPE {
    CITY = 'CITY',
    DISTRICT = 'DISTRICT',
    HAMLET = 'HAMLET',
    HANSEATIC_CITY = 'HANSEATIC_CITY',
    UNIFIED_MUNICIPALITY = 'UNIFIED_MUNICIPALITY',
    STATE_CAPITAL = 'STATE_CAPITAL',
    UNINCORPORATED_AREA = 'UNINCORPORATED_AREA',
    MUNICIPALITY = 'MUNICIPALITY',
    DISTRICT_TOWN = 'DISTRICT_TOWN',
    MARKET_TOWN = 'MARKET_TOWN',
}

export class MunicipalityTypeMapper {
    public static map(municipalityType: string): string {
        const values =
        {
            [MUNICIPALITY_TYPE.CITY.toString()]: 'Stadt',
            [MUNICIPALITY_TYPE.DISTRICT.toString()]: 'Kreis',
            [MUNICIPALITY_TYPE.HAMLET.toString()]: 'Flecken',
            [MUNICIPALITY_TYPE.HANSEATIC_CITY.toString()]: 'Hansestadt',
            [MUNICIPALITY_TYPE.UNIFIED_MUNICIPALITY.toString()]: 'Einheitsgemeinde',
            [MUNICIPALITY_TYPE.STATE_CAPITAL.toString()]: 'Landeshauptstadt',
            [MUNICIPALITY_TYPE.UNINCORPORATED_AREA.toString()]: 'Gemeindefreies Gebiet',
            [MUNICIPALITY_TYPE.MUNICIPALITY.toString()]: 'Gemeinde',
            [MUNICIPALITY_TYPE.DISTRICT_TOWN.toString()]: 'Kreisstadt',
            [MUNICIPALITY_TYPE.MARKET_TOWN.toString()]: 'Marktflecken',
        };

        return values[municipalityType];
    }
}

