import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ActivityService } from "../data-access/services";
import { STATUS } from "../enums";
import { ActivityModel } from "../models";
import { ActivityState } from "../states";


@Injectable({ providedIn: 'root' })
export class ActivityFacade {
    public activities$: Observable<ActivityModel[]>;
    public activity$: Observable<ActivityModel>;

    constructor(
        private activityService: ActivityService,
        private activityState: ActivityState,
    ) {
        this.activities$ = this.activityState.activities$;
        this.activity$ = this.activityState.activity$;
    }

    public getCurrentActivity(): ActivityModel {
        return this.activityState.activity;
    }

    public loadActivities(): void {
        this.activityService.getActivities().subscribe(
            (activities: ActivityModel[]) => this.activityState.setActivities(activities)
        );
    }

    public loadActivity(activityId: string): void {
        this.activityService.getActivity(activityId).subscribe(
            (activity: ActivityModel) => this.activityState.setActivity(activity)
        );
    }

    public createActivity(activity: Partial<ActivityModel>): Observable<ActivityModel> {
        return this.activityService.createActivity(activity).pipe(
            tap((activity: ActivityModel) => this.activityState.setActivity(activity))
        );
    }

    public updateActivity(activityId: string, activity: Partial<ActivityModel>): Observable<ActivityModel> {
        return this.activityService.updateActivity(activityId, activity).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }

    public updateActivityStatus(activityId: string, status: STATUS): Observable<ActivityModel> {
        return this.activityService.updateActivityStatus(activityId, status).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }

    public startPhase(activity: ActivityModel): Observable<ActivityModel> {
        return this.activityService.setPhase(activity).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }

    public endPhase(activityId: string): Observable<ActivityModel> {
        return this.activityService.endPhase(activityId).pipe(
            tap((result: ActivityModel) => this.activityState.setActivity(result))
        );
    }
}
